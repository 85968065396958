// const dynamic = dynamic(()=>import('next/dynamic'));
import dynamic from 'next/dynamic';
import Page from './global/Page';
const Post = dynamic(() => import('./global/Post'));
const Event = dynamic(() => import('./global/Event'));
const CustomerStory = dynamic(() => import('./global/CustomerStory'));
const Category = dynamic(() => import('./global/Category'));
import Section from './global/Section';
import LandingPage from './global/LandingPage';

const EventsLandingPage = dynamic(() => import('./landingPages/EventsLandingPage'));
const GetStartedLandingPage = dynamic(() => import('./landingPages/GetStartedLandingPage'));
const GetStartedLandingPageV3 = dynamic(() => import('./landingPages/GetStartedLandingPageV3'));
const CustomerStoriesLandingPage = dynamic(() => import('./landingPages/CustomerStoriesLandingPage'));
const PricingLandingPage = dynamic(() => import('./landingPages/PricingLandingPage'));
const BlogLandingPage = dynamic(() => import('./landingPages/BlogLandingPage'));
const GetAQuoteLandingPage = dynamic(() => import('./landingPages/GetAQuoteLandingPage'));
const CareersLandingPage = dynamic(() => import('./landingPages/CareersLandingPage'));
const SuccessStoriesLandingPage = dynamic(() => import('./landingPages/SuccessStoriesLandingPage'));
const ContractorTermsOfServiceLandingPage = dynamic(() => import('./landingPages/ContractorTermsOfServiceLandingPage'));
const AboutLandingPage = dynamic(() => import('./landingPages/AboutLandingPage/AboutLandingPage'));
const ContactLandingPage = dynamic(() => import('./landingPages/ContactLandingPage'));
const JoinProofLandingPage = dynamic(() => import('./landingPages/JoinProofLandingPage'));
const RequestDemoLandingPage = dynamic(() => import('./landingPages/RequestDemoLandingPage'));
const BecomeProcessServerLandingPage = dynamic(() => import('./landingPages/BecomeProcessServerLandingPage'));
const ServiceAreasHomeLandingPage = dynamic(() => import('./landingPages/ServiceAreasHomeLandingPage'));
const EbookLandingPage = dynamic(() => import('./landingPages/EbookLandingPage'));

import Column from './elements/Column';
import Buttons from './elements/Buttons';
import Button from './elements/Button';
import TextCard from './elements/TextCard';
import Media from './elements/Media';
const Gallery = dynamic(() => import('./elements/Gallery'));
import TickList from './elements/TickList';
const Form = dynamic(() => import('./elements/Form'));
const Modal = dynamic(() => import('./elements/Modal'));
const Embed = dynamic(() => import('./elements/Embed'));
const PricingBlock = dynamic(() => import('./elements/PricingBlock'));
import OneColumn from './flexible/OneColumn';
import TwoColumns from './flexible/TwoColumns';
import ThreeColumns from './flexible/ThreeColumns';
import FourColumns from './flexible/FourColumns';
const CustomComponents = dynamic(() => import('./flexible/CustomComponents'));
const StateList = dynamic(() => import('./custom/StateList'));
const Calculator = dynamic(() => import('./custom/Calculator'));
const GoogleReviewStars = dynamic(() => import('./custom/GoogleReviewStars'));
const PricingBlocks = dynamic(() => import('./custom/PricingBlocks'));
const ServiceAreaPricingBlocks = dynamic(() => import('./custom/ServiceAreaPricingBlocks'));
const ServiceAreaSearch = dynamic(() => import('./custom/ServiceAreaSearch'));
const StatsSection = dynamic(() => import('./custom/StatsSection'));
const BulkOrders = dynamic(() => import('./custom/BulkOrders'));
import Faqs from './custom/Faqs';
import IconList from './custom/IconList';
const ComparisonTable = dynamic(() => import('./custom/ComparisonTable'));
const IntegrationsListing = dynamic(() => import('./custom/IntegrationsListing'));
const TestimonialMediaSlider = dynamic(() => import('./custom/TestimonialMediaSlider'));
const Slider = dynamic(() => import('./custom/Slider'));
const SuccessStoriesSlider = dynamic(() => import('./custom/SuccessStoriesSlider'));
import InlineStats from './custom/InlineStats';
import TwoColumnTickList from './custom/TwoColumnTickList';
const TwoColStackingAnimation = dynamic(() => import('./custom/TwoColStackingAnimation'));
import FaqItem from './elements/FaqItem';
import IconListItem from './elements/IconListItem';
import IconText from './custom/IconText';
import DottedGrid from './custom/DottedGrid';
import EbookModalForm from './forms/EbookModalForm';
import BlogList from './custom/BlogList';
const HtmlEmbed = dynamic(() => import('./elements/HtmlEmbed'));
const TwoColumnAccordion = dynamic(() => import('./custom/TwoColumnAccordion'));
const TestimonialSection = dynamic(() => import('./custom/TestimonialSection'));

export default {
  page: Page,
  post: Post,
  customerStory: CustomerStory,
  event: Event,
  category: Category,
  section: Section,
  landingPage: LandingPage,
  eventsLandingPage: EventsLandingPage,
  getStartedLandingPage: GetStartedLandingPage,
  getStartedLandingPageV3: GetStartedLandingPageV3,
  customerStoriesLandingPage: CustomerStoriesLandingPage,
  pricingLandingPage: PricingLandingPage,
  blogLandingPage: BlogLandingPage,
  ebookLandingPage: EbookLandingPage,
  getAQuoteLandingPage: GetAQuoteLandingPage,
  careersLandingPage: CareersLandingPage,
  successStories: SuccessStoriesLandingPage,
  contractorTermsOfServiceLandingPage: ContractorTermsOfServiceLandingPage,
  aboutLandingPage: AboutLandingPage,
  contactLandingPage: ContactLandingPage,
  joinProofLandingPage: JoinProofLandingPage,
  requestDemoLandingPage: RequestDemoLandingPage,
  becomeProcessServerLandingPage: BecomeProcessServerLandingPage,
  serviceAreasHomeLandingPage: ServiceAreasHomeLandingPage,
  column: Column,
  buttons: Buttons,
  button: Button,
  textCard: TextCard,
  media: Media,
  gallery: Gallery,
  tickList: TickList,
  form: Form,
  modal: Modal,
  embed: Embed,
  pricingBlock: PricingBlock,
  oneColumn: OneColumn,
  twoColumns: TwoColumns,
  threeColumns: ThreeColumns,
  fourColumns: FourColumns,
  customComponents: CustomComponents,
  stateList: StateList,
  calculator: Calculator,
  googleReviewStars: GoogleReviewStars,
  pricingBlocks: PricingBlocks,
  serviceAreaPricingBlocks: ServiceAreaPricingBlocks,
  serviceAreaSearch: ServiceAreaSearch,
  statsSection: StatsSection,
  bulkOrders: BulkOrders,
  faqs: Faqs,
  faqItem: FaqItem,
  iconList: IconList,
  iconListItem: IconListItem,
  inlineStats: InlineStats,
  twoColumnTickList: TwoColumnTickList,
  comparisonTable: ComparisonTable,
  integrationsListing: IntegrationsListing,
  testimonialMediaSlider: TestimonialMediaSlider,
  twoColStackingAnimation: TwoColStackingAnimation,
  iconText: IconText,
  dottedGrid: DottedGrid,
  slider: Slider,
  ebookModalForm: EbookModalForm,
  blogList: BlogList,
  twoColumnAccordion: TwoColumnAccordion,
  testimonialSection: TestimonialSection,
  htmlEmbed: HtmlEmbed,
  successStoriesSlider: SuccessStoriesSlider
};

/**
 Tailwind un-purge class list

  pt-48 pb-48 pt-40 pb-40 pt-32 pb-32 pt-28 pb-28 pt-24 pb-24 pt-20 pb-20 pt-16 pb-16 pt-12 pb-12 pt-0 pb-0
  md:pt-48 md:pb-48 md:pt-40 md:pb-40 md:pt-32 md:pb-32 md:pt-28 md:pb-28 md:pt-24 md:pb-24 md:pt-20 md:pb-20 md:pt-16 md:pb-16 md:pt-12 md:pb-12 md:pt-0 md:pb-0
  lg:pt-48 lg:pb-48 lg:pt-40 lg:pb-40 lg:pt-32 lg:pb-32 lg:pt-28 lg:pb-28 lg:pt-24 lg:pb-24 lg:pt-20 lg:pb-20 lg:pt-16 lg:pb-16 lg:pt-12 lg:pb-12 lg:pt-0 lg:pb-0

  -ml-8 pl-8 -ml-12 pl-12

  grid-cols-2 grid-cols-3 grid-cols-4 grid-cols-5 grid-cols-6 grid-cols-7 grid-cols-8
  md:grid-cols-2 md:grid-cols-3 md:grid-cols-4 md:grid-cols-5 md:grid-cols-6 md:grid-cols-7 md:grid-cols-8

  w-1/12 w-2/12 w-3/12 w-4/12 w-5/12 w-6/12 w-7/12 w-8/12 w-9/12 w-10/12 w-11/12
  md:w-1/12 md:w-2/12 md:w-3/12 md:w-4/12 md:w-5/12 md:w-6/12 md:w-7/12 md:w-8/12 md:w-9/12 md:w-10/12 md:w-11/12
 */
