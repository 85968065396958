import React from 'react';
import { decode } from 'he';
import parse from 'html-react-parser';
import { storyblokEditable } from '@storyblok/react';
import { render, NODE_IMAGE } from 'storyblok-rich-text-react-renderer';

export default function RenderRichText({ content, ...other }) {
  if (!content) return null;

  const d = render(content, {
    //blokResolvers: components,
    nodeResolvers: {
      [NODE_IMAGE]: (children, props) => {
        let src = props?.src || '';
        const isStoryblok = src.includes('storyblok.com');
        const isSVG = src.endsWith('.svg');
        if (isStoryblok && !isSVG) {
          src += '/m/672x0';
        }
        return <img src={src} alt={props.alt || props.title} loading="lazy" />;
      }
    },
    // parse over rich text and allow html tags to be rendered
    textResolver: text => {
      return parse(decode(text || ''));
    }
  });

  return (
    <div {...storyblokEditable(content)} {...other}>
      {d}
    </div>
  );
}
