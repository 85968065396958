import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { useField } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  first_name: Yup.string().required('Please enter your first name'),
  email: Yup.string().email('Invalid email address').required('Please enter your email address')
});

function InputField(props) {
  const { label, reduced, placeholder, ...other } = props;
  const [field, meta, helpers] = useField(props);
  const isInvalid = meta.touched && meta.error;

  return (
    <label className="block">
      {reduced === false ? <div className="mb-1">{label}</div> : null}
      <input className={`${isInvalid ? 'border-accent' : ''}`} style={{ height: '48px' }} {...field} {...other} placeholder={reduced === true ? placeholder : null} />
    </label>
  );
}

export default function EbookForm({ reduced, blok, setExternalSubmitted }) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const { formSubmittedButtonText, formSubmittedButton, formSubmittedContent, formSubmittedHeading, formSubmittedRedirectUrl } = blok;

  useEffect(() => {
    if (setExternalSubmitted) {
      setExternalSubmitted(isSubmitted);
    }
    if (isSubmitted === true && formSubmittedRedirectUrl?.length > 0) {
      window.open(formSubmittedRedirectUrl, '_blank');
    }
  }, [isSubmitted]);

  return (
    <Formik
      initialValues={{
        first_name: '',
        email: ''
      }}
      validationSchema={validationSchema}
      onSubmit={async values => {
        setLoading(true);
        values.download_name = window.location.pathname;
        values.date_submitted = new Date().toISOString();
        try {
          const res = await fetch(`https://hooks.zapier.com/hooks/catch/14631794/3a8vzns/`, {
            method: 'POST',
            body: JSON.stringify(values)
          });

          const json = await res.json();

          if (json.status === 'success') {
            setIsSubmitted(true);
          }

          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      }}>
      {({ handleSubmit }) => {
        return !isSubmitted ? (
          <Form className="space-y-5">
            <InputField name="first_name" type="text" placeholder="Name" label="First Name" reduced={reduced} />
            <InputField name="email" type="email" placeholder="Enter email address" label="Email Address" reduced={reduced} />
            <button className="btn w-full" type="submit" onClick={handleSubmit}>
              Download
            </button>
          </Form>
        ) : (
          <div className={`space-y-6 ${reduced ? 'bg-white rounded-lg px-6 py-8' : ''}`}>
            <h3 className="u-h3">{formSubmittedHeading}</h3>
            <div className="" dangerouslySetInnerHTML={{ __html: formSubmittedContent }} />
            {formSubmittedButtonText ? (
              <a href={formSubmittedButton.url} className="btn" target="_blank" rel="noopener noreferrer`">
                {formSubmittedButtonText}
              </a>
            ) : null}
          </div>
        );

        // return (
        //   <Form className={""} >

        //     <InputField name="first_name" type="text" placeholder="Your first name" />
        //     <InputField name="email" type="email" placeholder="Your email address" />

        //     {(errorMessage || Object.keys(errors).length > 0) && (
        //       <ul className="mt-8 md:mt-10 md:flex md:flex-col space-y-4 md:space-y-0 u-e1 list-disc ml-6">
        //         {!!errorMessage && <li>{errorMessage}</li>}
        //         {Object.keys(flattenObject(errors))
        //           .filter(key => stepInputs.slice(0, activeStep).flat().includes(key))
        //           .map((key, i) => (
        //             <li key={i}>{flattenObject(errors)[key]}</li>
        //           ))}
        //       </ul>
        //     )}

        //     <div className="items-center justify-center mt-8 text-center md:mt-10 md:flex space-y-4 md:space-y-0">
        //       <button type="button" className="form-button" onClick={handleSubmit}>
        //         Submit
        //       </button>
        //     </div>

        //   </Form>
        // );
      }}
    </Formik>
  );
}
