import React, { useState, useEffect } from 'react';
import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import RenderRichText from '~/components/Storyblok/RenderRichText';
import VerticalWordsAnimation from '~/components/helpers/VerticalWordsAnimation';
import { fetchGeoLocation } from '~/utils/fetch-geo-location';

export default function TextCard({ blok }) {
  const [location, setLocation] = useState(null);

  const { headingFontSize, subheadingTag, headingTag, contentFontSize, contentClassName = '', textAlignment = '', headingColor = 'default' } = blok;
  const sectionYSpace = !headingFontSize || headingFontSize === 'u-h2' || headingFontSize === 'u-h1' ? 'space-y-6' : 'space-y-4';

  const headingBasedOnFontSize = () => {
    try {
      if (headingFontSize.startsWith('u-h')) {
        return headingFontSize.split('-')[1];
      }
    } catch (error) {
      return 'h2';
    }
  };

  const headingColorStyle = () => {
    if (headingColor === 'default') {
      return '';
    }

    if (headingColor === 'yellow') {
      return 'text-yellow';
    }

    return ``;
  };

  const SubheadingTag = subheadingTag || 'h2';
  const HeadingTag = headingTag || headingBasedOnFontSize() || 'h2';
  const hasWordsAnimation = blok.heading && blok.heading.includes('data-words');
  const geoLocationNeeded = blok?.heading?.includes('{{in_state}}');

  useEffect(async () => {
    if (geoLocationNeeded) {
      const loc = await fetchGeoLocation();
      setLocation(loc);
    }
  }, []);

  const isLocationUS = ['United States', 'US', 'USA', 'United States of America'].includes(location?.country);
  const parsedHeading = blok?.heading?.replace('{{in_state}}', `${isLocationUS ? `In ${location?.name || location?.state || location?.city}` : 'Nationwide'}`) || '';

  return (
    <div
      data-component="text-card"
      className={`text-card flex flex-col ${sectionYSpace} ${textAlignment} ${textAlignment === 'text-center' && 'items-center'} `}
      {...storyblokEditable(blok)}>
      {blok.subheading && <SubheadingTag className="u-s1" dangerouslySetInnerHTML={{ __html: blok.subheading }} />}
      {blok.heading && !hasWordsAnimation && (
        <HeadingTag
          className={`${headingFontSize || 'u-h2'} ${headingColorStyle()}`}
          dangerouslySetInnerHTML={{ __html: parsedHeading }}
          style={{ maxWidth: blok.headingMaxWidth || '100%', lineHeight: blok.headingLineHeight || '' }}
        />
      )}
      {blok.heading && hasWordsAnimation && (
        <VerticalWordsAnimation
          className={headingFontSize || 'u-h2'}
          heading={blok.heading}
          style={{ maxWidth: blok.headingMaxWidth || '100%', lineHeight: blok.headingLineHeight || '' }}
        />
      )}
      {blok.content && (
        <RenderRichText className={`${contentClassName} ${contentFontSize || 'u-p2'} prose-styling`} content={blok.content} style={{ maxWidth: blok.contentMaxWidth || '100%' }} />
      )}
      {blok.buttons && blok.buttons.length > 0 && <StoryblokComponent blok={blok.buttons[0]} />}
    </div>
  );
}
